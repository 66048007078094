"use client";
import { SUCURSALS } from "@/constants/data";
import Carousel from "../Carousel";
import DestinationCard from "./DestinationCard";
import { useEffect, useState } from "react";

function Destinations() {
  const [scrollAmount, setScrollAmount] = useState(300);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScrollAmount(window.innerWidth * 0.3);
    }
  }, []);

  return (
    <Carousel
      title="Buscá por destinos populares"
      ariaLabel="Sección de búsqueda de destinos populares"
      scrollAmount={scrollAmount}
    >
      {SUCURSALS.map(({ city, image, coords }) => (
        <DestinationCard
          key={city}
          city={city}
          image={image}
          location={coords}
        />
      ))}
    </Carousel>
  );
}

export default Destinations;
