"use client";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import React from "react";
import PurpleButton from "../Buttons/PurpleButton";
import SearchCars from "@/commons/CarSearch";
import useBookingDates from "@/hooks/useBookingDates";

function SuraInsurance() {
  const { location } = useBookingDates();
  const searchCars = SearchCars();

  return (
    <section
      className="w-full bg-violet-50 py-8 px-6 lg:px-16 rounded-md mt-16 mb-8 grid grid-cols-1 lg:flex gap-4 lg:gap-12 items-center justify-center"
      aria-labelledby="sura-insurance-title"
    >
      <div className="items-center justify-center min-w-fit">
        <Image
          src={`/assets/images/sura.webp`}
          alt="Logo de Seguros SURA"
          width={200}
          height={50}
          loading="lazy"
          className="w-full h-16 object-contain"
        />
      </div>
      <div className="text-center lg:text-left space-y-4">
        <h2 id="sura-insurance-title" className="md:text-xl font-bold">
          Seguros contra todo riesgo con SURA.
        </h2>
        <p className="text-sm/[25px] text-gray-700">
          En ATUA, la seguridad de nuestros clientes es una prioridad. Por eso,
          ofrecemos seguros contra todo riesgo con SURA, para que puedas
          disfrutar de tu viaje con total tranquilidad y confianza.
        </p>
        <div className="flex gap-2 items-center justify-center lg:justify-normal">
          <PhoneArrowDownLeftIcon className="h-5" aria-hidden="true" />
          <p className="text-lg font-bold">Atención las 24 horas</p>
        </div>
        <PurpleButton
          label="Buscá tu auto"
          ariaLabel="Buscar autos asegurados con SURA"
          onClick={() => searchCars.handleSearch(location)}
        />
      </div>
    </section>
  );
}

export default SuraInsurance;
