import React from 'react'

function LoadingViews() {
  return (
    <>
      <div className=" h-[65vh] w-full inset-0 flex items-center justify-center bg-white bg-opacity-95">
      <div className="animate-spin rounded-full h-20 w-20 border-t-8 border-b-8 border-purple-700"></div>
    </div>
    </>
  )
}

export default LoadingViews
