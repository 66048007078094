"use client";

import { USERS_REVIEWS } from "@/constants/data";
import Carousel from "../Carousel";
import TestimonialsCard from "./TestimonialsCard";
import { useEffect, useState } from "react";

function Testimonials() {
  const [scrollAmount, setScrollAmount] = useState(300);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScrollAmount(window.innerWidth * 0.3);
    }
  }, []);

  return (
    <Carousel
      title="Conocé a nuestros anfitriones"
      ariaLabel="Sección de testimonios de nuestros anfitriones"
      scrollAmount={scrollAmount}
    >
      {USERS_REVIEWS.map(({ userName, photo, review }, index) => (
        <TestimonialsCard
          key={index}
          userName={userName}
          photo={photo}
          review={review}
        />
      ))}
    </Carousel>
  );
}

export default Testimonials;
