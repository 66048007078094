"use client";
import React from "react";
import FeatureSection from "./FeatureSection";
import SearchCars from "@/commons/CarSearch";
import LinksNavigation from "@/constants/navigation";
import useBookingDates from "@/hooks/useBookingDates";

function FeatureSectionGroup() {
  const searchCars = SearchCars();
  const links = LinksNavigation();
  const { location } = useBookingDates();

  return (
    <div className="space-y-8 md:space-y-0 pt-8">
      <FeatureSection
        title={
          <>
            Alquilá al <span className="text-violet-700">mejor precio</span>.
          </>
        }
        description="Nuestra plataforma conecta directamente a los viajeros con anfitriones locales, lo que significa que evitás los costos adicionales de intermediarios y obtenés tarifas competitivas."
        buttonText="Buscá tu auto"
        buttonAction={() => searchCars.handleSearch(location)}
        imageSrc="/assets/images/alquila.webp"
        imageAlt="Auto alquilado"
      />
      <FeatureSection
        title={
          <>
            Impacto <span className="text-violet-700">social</span>.
          </>
        }
        description="Alquilando con ATUA, no sólo conseguís un auto de calidad al mejor precio, sino que también apoyás a anfitriones locales en diferentes provincias de Argentina. Contribuí a la economía local mientras disfrutás de tu viaje."
        imageSrc="/assets/images/impacto.webp"
        imageAlt="Impacto social"
        isReversed
      />
      <FeatureSection
        title={
          <>
            Proceso <span className="text-violet-700">100% digital</span>, sin
            trámites.
          </>
        }
        description="En ATUA, todo el proceso es completamente digital y sin complicaciones. Reservá, pagá y obtené tu auto de forma rápida y sencilla, todo desde la comodidad de tu dispositivo."
        imageSrc="/assets/images/digital.webp"
        imageAlt="Proceso digital"
      />
      <FeatureSection
        title={
          <>
            Alquilá tu auto y{" "}
            <span className="text-violet-700">ganá dinero</span>.
          </>
        }
        description="Publicá tu auto en ATUA y empezá a generar ingresos extra de forma segura y rápida. ¡Sumate a nuestra comunidad de anfitriones!"
        buttonText="Publicá tu auto"
        buttonAction={links.handleClickBecomeHost}
        imageSrc="/assets/images/publica.webp"
        imageAlt="Publicá tu auto"
        isReversed
      />
    </div>
  );
}

export default FeatureSectionGroup;
