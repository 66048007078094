"use client";
import React from "react";
import Image from "next/image";

const HeroSection: React.FC = () => {
  return (
    <header className="relative w-full h-[300px] sm:h-[400px] 2xl:h-[600px] items-center">
      {/* Imagen optimizada */}
      <Image
        src="/assets/images/frontpage.webp"
        alt="Atua Logo"
        blurDataURL="/assets/images/frontpage.webp"
        placeholder="blur"
        fill={true}
        priority={true}
        sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw"
        className="object-cover w-full h-full"
      />
    </header>
  );
};

export default HeroSection;
