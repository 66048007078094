"use client";
import SearchBar from "@/components/Header/SearchBar/SearchBar";
import LoadingViews from "@/components/Skeleton/LoadingViews";
import MainScreen from "@/components/MainScreen";
import { Suspense } from "react";
import Testimonials from "@/components/Carrousels/HostCarrousel/Testimonials";
import SectionHeader from "@/components/Titles/SectionHeader";
import Destinations from "@/components/Carrousels/CityCarrousel/Destinations";
import SuraInsurance from "@/components/Home/SuraInsurance";
import FeatureSectionGroup from "@/components/Home/Features/FeatureSectionGroup";
import HeroSection from "@/components/Home/HeroSection";

export default function Home() {
  return (
    <>
      <Suspense fallback={<LoadingViews />}>
        <MainScreen isHomePage={true}>
          <div className="relative items-center justify-center">
            {/* Background Image */}
            <HeroSection />

            {/* Search Bar on top of background */}
            <div className="absolute top-8 w-full">
              <div className="flex justify-center">
                <div className="max-w-5xl w-full flex justify-center items-center">
                  <div className="mx-auto px-6">
                    <SearchBar landingPage={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section title */}
          <main className="max-w-7xl mx-auto px-5 sm:px-20">
            <div
              className="py-8 text-center mt-6"
              aria-label="Sección de alquiler de autos"
            >
              <h2 className="text-4xl font-bold pb-2">
                ¡Buscá, reservá y viajá!
              </h2>

              <div className="flex justify-center">
                <div className="bg-gradient-to-t from-violet-100 to-transparent text-center rounded-md">
                  <h1 className="text-xs md:text-base p-1 px-5 inline-block">
                    Alquiler de autos entre particulares en toda Argentina al
                    mejor precio.
                  </h1>
                </div>
              </div>
            </div>

            {/* Info sectión */}
            <FeatureSectionGroup />

            <SuraInsurance />

            {/* Section title */}
            <SectionHeader
              title="Siempre cerca de ti"
              subtitle="Encontranos en cualquiera de estas ciudades, con host verificados."
              className="mt-6"
              ariaLabel="Sección de ciudades cercanas"
            />

            {/* City Carrousel */}
            <Destinations />

            {/* Section title */}
            <SectionHeader
              title="Conocé a nuestros anfitriones"
              subtitle="Personas que gracias a la plataforma están generando ingresos extras."
              className="mt-6"
              ariaLabel="Sección de anfitriones"
            />

            {/* Host Carrousel */}
            <Testimonials />

            {/* City Carrousel */}
          </main>
        </MainScreen>
      </Suspense>
    </>
  );
}
