"use client";
import React from "react";

interface ButtonProps {
  label: string;
  ariaLabel?: string;
  onClick: () => void;
}

const PurpleButton: React.FC<ButtonProps> = ({ label, ariaLabel, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-violet-700 text-white px-4 py-2 rounded-lg font-semibold shadow-sm hover:scale-[1.02] hover:shadow-md items-center transition duration-300"
      aria-label={ariaLabel || label}
    >
      {label}
    </button>
  );
};

export default PurpleButton;
