import Image from "next/image";
import React from "react";

interface Props {
  city: string;
  image: string;
  location: string;
}

function DestinationCard({ city, image, location }: Props) {
  return (
    <div
      onClick={() => window.open(location, "_blank")}
      className="z-10 min-w-[169px] rounded-md cursor-pointer shadow-md p-5 hover:bg-gray-50 hover:shadow-lg hover:scale-105 transform transition duration-300 ease-out text-center"
      role="button"
      aria-label={`Buscar autos en ${city}`}
    >
      <div className="pb-1">
        <Image
          src={`/assets/provinces/${image}`}
          alt={`Vista de la ciudad de ${city}`}
          width={120}
          height={120}
          loading="lazy"
          className="cursor-pointer rounded-lg"
        />
      </div>
      <h3 className="font-semibold mt-3">{city}</h3>
    </div>
  );
}

export default DestinationCard;
