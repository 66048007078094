import { StarIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import React from "react";

interface Props {
  userName: string;
  photo: string;
  review: string;
}

function TestimonialsCard({ userName, photo, review }: Props) {
  return (
    <div
      className="min-w-[320px] shadow-md p-6 rounded-md hover:shadow-lg 
    hover:scale-105 transform transition duration-300 ease-out text-center z-10"
      role="listitem"
    >
      <div className="flex space-x-4 items-center">
        <div className="relative">
          <Image
            src={`/assets/images/${photo}`}
            alt={`Foto de perfil de ${userName}`}
            width={80}
            height={80}
            layout="fixed"
            className="rounded-full"
            loading="lazy"
          />
        </div>
        <div>
          <p className="text-base md:text-xl font-bold pb-2">{userName}</p>
          <div className="flex">
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <StarIcon key={i} className="text-violet-700 h-5" />
              ))}
          </div>
        </div>
      </div>
      <p className="mt-4 text-sm text-justify">{review}</p>
    </div>
  );
}

export default TestimonialsCard;
