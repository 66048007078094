'use client'
import React, { useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

interface CarouselProps {
  children: React.ReactNode;
  title: string;
  scrollAmount?: number;
  className?: string;
  ariaLabel?: string;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  title,
  scrollAmount = 300,
  className = "",
  ariaLabel = "Sección de carrusel",
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (amount: number) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: amount,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className={className} aria-label={ariaLabel}>
      <div className="py-4 flex justify-between items-center">
        <h2 className="text-base md:text-xl font-bold pb-2">{title}</h2>
        <div className="flex space-x-2">
          <ChevronLeftIcon
            className="h-6 text-gray-500 hover:scale-105 cursor-pointer"
            onClick={() => handleScroll(-scrollAmount)}
            aria-label="Scroll Left"
          />
          <ChevronRightIcon
            className="h-6 text-gray-500 hover:scale-105 cursor-pointer"
            onClick={() => handleScroll(scrollAmount)}
            aria-label="Scroll Right"
          />
        </div>
      </div>

      <div
        className="flex overflow-x-auto scrollbar-hide space-x-6 py-4"
        ref={scrollContainerRef}
      >
        {children}
      </div>
    </section>
  );
};

export default Carousel;
