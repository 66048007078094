import React from "react";

interface SectionHeaderProps {
  title?: string;
  subtitle?: string;
  className?: string; // Para agregar clases adicionales si se necesita
  ariaLabel?: string; // Mejora la accesibilidad
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  className = "",
  ariaLabel = "",
}) => {
  return (
    <div className={`py-8 text-center ${className}`} aria-label={ariaLabel}>
      {title && <h2 className="text-4xl font-bold pb-2">{title}</h2>}
      {subtitle && (
        <div className="flex justify-center">
          <div className="bg-gradient-to-t from-violet-100 to-transparent text-center rounded-md">
            <p className="text-xs md:text-base p-1 px-5 inline-block">
              {subtitle}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionHeader;