"use client";
import React from "react";
import Image from "next/image";
import PurpleButton from "@/components/Buttons/PurpleButton";

interface FeatureProps {
  title: React.ReactNode;
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  imageSrc: string;
  imageAlt: string;
  isReversed?: boolean;
}

const FeatureSection: React.FC<FeatureProps> = ({
  title,
  description,
  buttonText,
  buttonAction,
  imageSrc,
  imageAlt,
  isReversed = false,
}) => {
  return (
    <section
      className={`w-full rounded-md grid grid-cols-1 md:flex items-center justify-center gap-10 ${
        isReversed ? "" : "md:flex-row-reverse"
      }`}
      aria-labelledby={`feature-section-${title
        ?.toString()
        .toLowerCase()
        .replace(/\s+/g, "-")}`}
    >
      <div
        className={`text-center space-y-5 md:max-w-[50%] ${
          isReversed ? "md:text-right" : "md:text-left"
        }`}
      >
        <h2
          id={`feature-section-${title
            ?.toString()
            .toLowerCase()
            .replace(/\s+/g, "-")}`}
          className="md:text-2xl font-extrabold"
        >
          {title}
        </h2>
        <p className="text-sm/[25px]">{description}</p>
        {buttonText && (
          <PurpleButton
            label={buttonText}
            onClick={
              buttonAction ||
              (() => console.log("No se ha definido una acción"))
            }
            ariaLabel={`Acción: ${buttonText}`}
          />
        )}
      </div>
      <div className="items-center justify-center min-w-[50%] px-5">
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={250}
          height={120}
          loading="lazy"
          className="w-full h-full object-cover"
        />
      </div>
    </section>
  );
};

export default FeatureSection;
